<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
        <el-button @click="onHandleZone('addData')" type="primary">
          新 增
        </el-button>
      </div>
      <div class="d-flex headSearch">
        <div class="form-item">
          <el-select v-model="formData.title" clearable placeholder="选择标题">
            <el-option
              v-for="item of titleList"
              :key="item.title"
              :label="item.title"
              :value="item.title"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.region_sort"
            clearable
            placeholder="选择分区"
          >
            <el-option
              v-for="item of partitionList"
              :key="item.regionSort"
              :label="item.regionName"
              :value="item.regionSort"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.status"
            filterable
            clearable
            placeholder="选择状态"
          >
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.logistic_business_id"
            clearable
            placeholder="选择集配中心"
          >
            <el-option
              v-for="item in businessList"
              :key="item.logisticBusinessId"
              :label="item.logisticBusinessName"
              :value="item.logisticBusinessId"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item btn-wrap">
          <el-button
            @click="onHandleSearch"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
        </div>
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        :jumpTypeList="jumpTypeList"
        @submit-form="submitForm"
        @on-handle-zone="onHandleZone"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 新增编辑弹窗 start -->
    <zone-config-dialog
      ref="zoneConfig"
      :businessList="businessList"
      :partitionList="partitionList"
      :jumpTypeList="jumpTypeList"
      @submit-form="submitForm"
      @handle-picture-card-preview="handlePictureCardPreview"
    ></zone-config-dialog>
    <!-- 新增编辑弹窗 end -->

    <!-- 上传图片预览 start -->
    <el-dialog :visible.sync="dialogImage" width="80%">
      <img width="100%" :src="bigImage" alt="" />
    </el-dialog>
    <!-- 上传图片预览 end -->
  </section>
</template>
<script>
import ZoneConfigDialog from "./modules/addConfig/index.vue";
import {
  postVersatileRegionList,
  postRegionSelectConfig,
} from "@/api/generalControl/zone";
import TableList from "./modules/table-list/index.vue";
import { STATE_ENUM } from "./utils/enum";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ZoneConfig",
  components: { ZoneConfigDialog, TableList },
  data() {
    return {
      dialogImage: false,
      bigImage: "",
      total: 0,
      loadingData: false,
      formData: {
        logistic_business_id: "", // 集配中心id
        region_sort: "",
        title: "",
        status: "",
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制数据，用于初始化
      stateList: Object.values(STATE_ENUM), //状态列表
      titleList: [], // 标题数据
      partitionList: [], // 分区选择下拉
      tableData: [], //列表数据
      businessList: [], //集配中心数据
      jumpTypeList: [], // 跳转页面列表
    };
  },
  created() {
    this.formDataClone = cloneDeep(this.formData);
    this.initData();
  },
  methods: {
    /**
     * 上传图片 预览
     */
    handlePictureCardPreview(file) {
      console.log("object", file);
      this.bigImage = file.url;
      this.dialogImage = true;
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.postAjaxVersatileRegionList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.postAjaxVersatileRegionList();
    },
    /**
     * 查询
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.postAjaxVersatileRegionList();
    },
    /**
     * 初始化数据
     */
    initData() {
      this.postAjaxVersatileRegionList();
      this.postAjaxRegionSelectConfig();
    },
    /**
     * 获得筛选所需配置数据
     */
    async postAjaxRegionSelectConfig() {
      try {
        const { data } = await postRegionSelectConfig();
        this.businessList = data.businessConfig || [];
        this.partitionList = data.regionConfig || [];
        this.titleList = data.titleConfig || [];
        this.jumpTypeList = data.jumpConfig || [];
      } catch (err) {
        console.error("ajax postRegionSelectConfig err", err);
      }
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.postAjaxVersatileRegionList();
    },
    /**
     * 新增编辑配置
     */
    onHandleZone(func, val) {
      this.$refs.zoneConfig[func](val);
    },
    /**
     * 刷新列表
     */
    submitForm() {
      this.formData.page = 1;
      this.postAjaxVersatileRegionList();
      this.postAjaxRegionSelectConfig();
    },
    /**
     * 获得列表数据
     */
    async postAjaxVersatileRegionList() {
      this.loadingData = true;
      try {
        const { data } = await postVersatileRegionList(this.formData);
        this.tableData = data.data;
        this.total = data.total;
      } catch (err) {
        console.error("ajax postVersatileRegionList err", err);
      } finally {
        this.loadingData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .headSearch {
    flex: 1;
    justify-content: end;
    gap: 15px;
  }
}
</style>
