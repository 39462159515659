var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "logisticBusinessName",
          align: "center",
          "min-width": "100",
          label: "集配中心",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "regionName",
          align: "center",
          label: "分区",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "title",
          align: "center",
          label: "标题",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_c("span", [_vm._v(_vm._s(scope.row.title || "-"))])]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "image",
          align: "center",
          label: "图片",
          "min-width": "100",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (scope) {
                return scope.row.image
                  ? [
                      _c("el-image", {
                        staticStyle: { width: "30px", height: "30px" },
                        attrs: {
                          src: scope.row.image,
                          "preview-src-list": [scope.row.image],
                        },
                      }),
                    ]
                  : undefined
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "gift_amount",
          align: "center",
          label: "类型",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.jumpTypeList.find(
                        (item) => item.urlType == scope.row.urlType
                      )?.urlName || "-"
                    )
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "url",
          align: "center",
          label: "链接",
          "min-width": "120",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "status", align: "center", label: "状态" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type:
                        _vm.stateList.find((item) => {
                          return item.value === (scope.row.status || 1)
                        }).label == "启用"
                          ? "success"
                          : "warning",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.stateList.find((item) => {
                          return item.value === (scope.row.status || 1)
                        }).label
                      ) + " "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "purchase_deduction_amount",
          align: "center",
          label: "操作",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleEdit(scope.row)
                      },
                    },
                  },
                  [_vm._v("编辑 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleDelete(scope.row)
                      },
                    },
                  },
                  [_vm._v(" 删除 ")]
                ),
                scope.row.urlType ==
                _vm.jumpTypeList.find((item) => item.urlName == "自定义")
                  ?.urlType
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleDownLoad(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 导出 ")]
                    )
                  : _vm._e(),
                scope.row.urlType ==
                _vm.jumpTypeList.find((item) => item.urlName == "自定义")
                  ?.urlType
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 关联商品 ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleQrCode(scope.row)
                      },
                    },
                  },
                  [_vm._v(" 下载小程序码 ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }