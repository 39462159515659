<template>
  <div>
    <!--  新增编辑专区配置弹出框  -->
    <el-dialog
      :title="platFormTitle"
      :visible.sync="dialogFormVisible"
      width="60%"
      class="dialog"
      center
    >
      <div class="contents1">
        <el-form
          :model="platForm"
          :rules="rules"
          ref="platForm"
          label-width="auto"
        >
          <el-form-item label="集配业务中心" prop="logistic_business_id">
            <el-select
              v-model="platForm.logistic_business_id"
              clearable
              placeholder="选择集配业务中心"
            >
              <el-option
                v-for="item in businessList"
                :key="item.logisticBusinessId"
                :label="item.logisticBusinessName"
                :value="item.logisticBusinessId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分区" prop="region_sort">
            <el-select
              v-model="platForm.region_sort"
              clearable
              placeholder="选择分区"
              @change="changeRegion"
            >
              <el-option
                v-for="item in partitionList"
                :key="item.regionSort"
                :label="item.regionName"
                :value="item.regionSort"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标题" prop="title">
            <div>
              <el-input
                v-model="platForm.title"
                placeholder="请输入标题"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="分区图片" prop="image">
            <div class="d-flex a-center">
              <el-upload
                v-loading="loadingImage"
                element-loading-text="正在上传..."
                action="#"
                :on-preview="handlePictureCardPreview"
                :class="{ hide: image.length == 1 }"
                :file-list="image"
                list-type="picture-card"
                :http-request="
                  (file) => {
                    return uploadFile(file, 'image');
                  }
                "
                :before-remove="
                  (file, fileList) => {
                    return deleteFile(file, fileList, 'image');
                  }
                "
                :limit="1"
              >
                <i slot="trigger" class="el-icon-plus"></i>
              </el-upload>
              <span class="tipWrap">
                {{
                  partitionList.find(
                    (item) => item.regionSort == platForm.region_sort
                  )?.description || ""
                }}
              </span>
            </div>
          </el-form-item>
          <el-form-item label="类型" prop="url_type">
            <el-select
              v-model="platForm.url_type"
              clearable
              placeholder="选择类型"
              @change="changeUrlType"
            >
              <el-option
                v-for="item in jumpTypeList"
                :key="item.urlType"
                :label="item.urlName"
                :value="item.urlType"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 类型选择自定义 需要上传banner -->
          <el-form-item
            label="banner图片"
            prop="banner_url"
            v-if="
              platForm.url_type ==
              jumpTypeList.find((item) => item.urlName == '自定义')?.urlType
            "
          >
            <el-upload
              v-loading="loadingBanner"
              element-loading-text="正在上传..."
              action="#"
              :on-preview="handlePictureCardPreview"
              :class="{ hide: banner_url.length == 1 }"
              :file-list="banner_url"
              list-type="picture-card"
              :http-request="
                (file) => {
                  return uploadFile(file, 'banner_url');
                }
              "
              :before-remove="
                (file, fileList) => {
                  return deleteFile(file, fileList, 'banner_url');
                }
              "
              :limit="1"
            >
              <i slot="trigger" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="参数" prop="url_param" v-else>
            <div class="d-flex">
              <div style="width: 200px">
                <el-input
                  v-model="platForm.url_param"
                  placeholder="请输入参数"
                  clearable
                ></el-input>
              </div>
              <span class="tipWrap">
                类型选择商品详情页,则参数填写需要跳转的货号;如果类型选择分类页,则填写对应的品类ID
              </span>
            </div>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="platForm.status">
              <el-radio
                :label="item.value"
                v-for="item of stateList"
                :key="item.value"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { compress } from "image-conversion";
import { Loading } from "element-ui";
import {
  postVersatileRegionAdd,
  postVersatileRegionEdit,
  postVersatileRegionInfo,
} from "@/api/generalControl/zone";
import { STATE_ENUM } from "../../utils/enum";
export default {
  props: ["businessList", "partitionList", "jumpTypeList"],
  name: "addConfigDialog",
  data() {
    return {
      stateList: Object.values(STATE_ENUM), //状态列表
      loadingImage: false, // 上传分区图片loading
      loadingBanner: false, // 上传自定义banner图片loading
      dialogFormVisible: false, //编辑弹窗
      platFormTitle: "", //弹框title
      platFormClone: {}, // 复制手续费配置数据，用于初始化
      platForm: {
        logistic_business_id: "",
        logistic_business_name: "",
        region_sort: "",
        title: "",
        image: "",
        url_type: "",
        url_param: "",
        banner_url: "",
        status: 1,
      },
      image: [], // 分区图片
      banner_url: [], // banner图片
      rules: {
        image: [
          {
            required: true,
            message: "请上传图片",
            trigger: ["blur", "change"],
          },
        ],
        url_param: [
          {
            required: true,
            message: "请填写参数",
            trigger: ["blur", "change"],
          },
        ],
        banner_url: [
          {
            required: true,
            message: "请上传banner图片",
            trigger: ["blur", "change"],
          },
        ],
        logistic_business_id: [
          {
            required: true,
            message: "请选择集配中心",
            trigger: ["blur", "change"],
          },
        ],
        region_sort: [
          {
            required: true,
            message: "请选择分区",
            trigger: ["blur", "change"],
          },
        ],
        title: [
          {
            required: true,
            message: "请输入标题",
            trigger: ["blur", "change"],
          },
        ],
        url_type: [
          {
            required: true,
            message: "请选择类型",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.platFormClone = cloneDeep(this.platForm);
  },
  methods: {
    /**
     * 选择分区 修改
     */
    changeRegion(e) {
      let tmpRegionSort = this.partitionList.find(
        (item) => item.regionName == "其他专区"
      )?.regionSort; // 获取其他专区id
      if (e == tmpRegionSort) {
        this.rules.image[0].required = false; // 选择其他分区 分区图片不必传
      } else {
        this.rules.image[0].required = true;
      }
      this.$refs.platForm?.validateField("image");
    },
    /**
     * 选择类型 修改
     */
    changeUrlType(e) {
      // 切换类型 清空banner图片 参数
      this.platForm.banner_url = "";
      this.banner_url = [];
      this.platForm.url_param = "";
      let tmpUrlType = this.jumpTypeList.find(
        (item) => item.urlName == "自定义"
      )?.urlType; // 获取自定义跳转id

      // 选择自定义 参数不必传 banner图片必传
      if (e == tmpUrlType) {
        this.rules.url_param[0].required = false;
        this.rules.banner_url[0].required = true;
      } else {
        this.rules.url_param[0].required = true;
        this.rules.banner_url[0].required = false;
      }
      this.$refs.platForm?.validateField("url_param");
      this.$refs.platForm?.validateField("banner_url");
    },
    /**
     * 预览图片
     */
    handlePictureCardPreview(file) {
      this.$emit("handle-picture-card-preview", file);
    },
    /**
     * 删除上传图片
     */
    deleteFile(file, fileList, name) {
      console.log(fileList, file, "删除");
      this[name] = fileList;
      this.platForm[name] = ""; // 清空表单图片地址
    },
    /**
     * 上传
     */
    uploadFile(options, name) {
      // 拿到 file
      if (name == "image") {
        this.loadingImage = true;
      } else {
        this.loadingBanner = true;
      }
      let file = options.file;
      let date = new Date().getTime();
      console.log(file, "文件");
      console.log(file.size / 1024 / 1024);
      compress(file, 0.9).then((res) => {
        console.log(res);
        console.log(res.size / 1024 / 1024);
        if (res.size / 1024 / 1024 > 50) {
          this.tool.message("图片超过50M,请重新上传", "error");
          if (name == "image") {
            this.loadingImage = false;
          } else {
            this.loadingBanner = false;
          }
        } else {
          var event = event || window.event;
          var reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onload = (e) => {
            console.log(e);
            this.uploadImage(e.target.result, name);
          };
        }
      });
    },
    async uploadImage(img, name) {
      try {
        let { data } = await this.$api.general.uploadImage({ file_data: img });
        this[name] = [{ url: data }];
        this.platForm[name] = data;
        console.log(this[name], this.platForm[name]);
      } catch (error) {
        console.error("ajax postBannerUpload err", error);
      } finally {
        if (name == "image") {
          this.loadingImage = false;
        } else {
          this.loadingBanner = false;
        }
      }
    },
    /**
     *初始化数据
     */
    initData() {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs.platForm.clearValidate();
      });
    },
    /**
     *新增
     */
    addData() {
      this.platFormTitle = "新增";
      this.platForm = {
        ...this.platFormClone,
      };
      this.image = [];
      this.banner_url = [];
      this.initData();
    },
    /**
     * 编辑 获取详情
     */
    async editData(val) {
      console.log(val);
      this.platFormTitle = "编辑";
      let load = Loading.service({ fullscreen: true });
      try {
        let { data } = await postVersatileRegionInfo({ id: val.id });
        for (let key in this.platForm) {
          this.platForm[key] = data[key];
        }
        this.platForm.id = data.id; // 编辑id
        this.platForm.logistic_business_id = data.logisticBusinessId;
        this.platForm.region_sort = Number(data.regionSort);
        this.image = data.image ? [{ url: data.image }] : [];
        this.platForm.url_type = data.urlType;
        this.platForm.url_param = data.urlParam;
        this.platForm.banner_url = data.bannerUrl;
        this.banner_url = data.bannerUrl ? [{ url: data.bannerUrl }] : [];
        this.changeRegion(this.platForm.region_sort); //校验 编辑分区 分区图片是否必传
      } catch (err) {
        console.error("ajax postVersatileRegionInfo err", err);
      } finally {
        load.close();
        this.initData();
      }
    },
    /**
     * 编辑提交表单
     */
    submitFrom: debounce(function () {
      this.$refs.platForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        this.platForm.logistic_business_name = this.businessList.find(
          (item) =>
            item.logisticBusinessId == this.platForm.logistic_business_id
        )?.logisticBusinessName; // 获取集配中心名称

        const tmpParam = cloneDeep(this.platForm);

        let tmpUrlType = this.jumpTypeList.find(
          (item) => item.urlName == "自定义"
        )?.urlType; // 获取自定义跳转id
        if (tmpParam.url_type == tmpUrlType) {
          delete tmpParam.url_param;
        } else {
          delete tmpParam.banner_url;
        }
        try {
          if (this.platForm.id) {
            await postVersatileRegionEdit(tmpParam);
            this.tool.message("编辑成功", "success");
          } else {
            await postVersatileRegionAdd(tmpParam);
            this.tool.message("新增成功", "success");
          }
          this.$emit("submit-form"); // 刷新列表
          this.dialogFormVisible = false;
        } catch (error) {
          console.error("postForceIntervene", error);
        }
      });
    }, 300),
  },
};
</script>
<style scoped lang="scss">
.contents1 {
  box-sizing: border-box;
  padding: 10px 26px 40px;

  .hide {
    /deep/ .el-upload--picture-card {
      display: none;
    }
  }
  .tipWrap {
    color: red;
    margin-left: 10px;
  }
}
</style>
