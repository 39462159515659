<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column
      prop="logisticBusinessName"
      align="center"
      min-width="100"
      label="集配中心"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="regionName"
      align="center"
      label="分区"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="title"
      align="center"
      label="标题"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.title || "-" }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="image" align="center" label="图片" min-width="100">
      <template slot-scope="scope" v-if="scope.row.image">
        <el-image
          style="width: 30px; height: 30px"
          :src="scope.row.image"
          :preview-src-list="[scope.row.image]"
        ></el-image>
      </template>
    </el-table-column>
    <el-table-column
      prop="gift_amount"
      align="center"
      label="类型"
      min-width="100"
    >
      <template slot-scope="scope">
        <span>{{
          jumpTypeList.find((item) => item.urlType == scope.row.urlType)
            ?.urlName || "-"
        }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="url" align="center" label="链接" min-width="120">
    </el-table-column>
    <el-table-column prop="status" align="center" label="状态">
      <template slot-scope="scope">
        <el-tag
          :type="
            stateList.find((item) => {
              return item.value === (scope.row.status || 1);
            }).label == '启用'
              ? 'success'
              : 'warning'
          "
          >{{
            stateList.find((item) => {
              return item.value === (scope.row.status || 1);
            }).label
          }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
      prop="purchase_deduction_amount"
      align="center"
      label="操作"
    >
      <template slot-scope="scope">
        <el-button type="text" @click="onHandleEdit(scope.row)"
          >编辑
        </el-button>
        <el-button type="text" @click="onHandleDelete(scope.row)">
          删除
        </el-button>
        <el-button
          type="text"
          @click="onHandleDownLoad(scope.row)"
          v-if="
            scope.row.urlType ==
            jumpTypeList.find((item) => item.urlName == '自定义')?.urlType
          "
        >
          导出
        </el-button>
        <el-button
          type="text"
          @click="onHandleDetail(scope.row)"
          v-if="
            scope.row.urlType ==
            jumpTypeList.find((item) => item.urlName == '自定义')?.urlType
          "
        >
          关联商品
        </el-button>
        <el-button type="text" @click="onHandleQrCode(scope.row)">
          下载小程序码
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { STATE_ENUM } from "../../utils/enum";
import { getVersatileRegionProductOrderExport } from "@/api/export/index.js";
import { postVersatileRegionDelete } from "@/api/generalControl/zone";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { getMiniAppQrCode } from "@/api/export";
import { MEMBER_QR_CODE_CONFIG, MINI_APP_PR_CODE_PAGE } from "@/utils/enum";

export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    jumpTypeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      MINI_APP_PR_CODE_PAGE,
      MEMBER_QR_CODE_CONFIG,
      stateList: Object.values(STATE_ENUM),
    };
  },
  methods: {
    /**
     * 编辑
     */
    onHandleEdit(row) {
      console.log("编辑", row);
      this.$emit("on-handle-zone", "editData", row);
    },
    /**
     * 删除
     */
    onHandleDelete(row) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          try {
            const res = await postVersatileRegionDelete({ id: row.id });
            this.$message({
              type: "success",
              message: "已删除",
            });
            this.$emit("submit-form");
          } catch (error) {}
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "取消删除",
          });
        });
    },
    /**
     * 下载
     */
    async onHandleDownLoad(row) {
      this.$message.success("正在导出，请耐心等待");
      try {
        const res = await getVersatileRegionProductOrderExport({
          id: row.id,
        });
        downLoadFlowBlob(res, res?.filename);
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax getVersatileRegionProductOrderExport err", err);
      }
    },
    /**
     * 关联商品
     */
    onHandleDetail(row) {
      this.$router.push({
        path: `/association`,
        query: {
          id: row.id,
        },
      });
    },
    /**
     * 展示二维码
     */
    async onHandleQrCode(row) {
      const { cate } = MEMBER_QR_CODE_CONFIG[process.env.NODE_ENV];
      const params = {
        env_version: cate.envCode,
        type: MINI_APP_PR_CODE_PAGE.zone.value,
        from_page: row.url,
      };
      try {
        const res = await getMiniAppQrCode(params);
        console.log("ajax getMiniAppQrCode", res);
        downLoadFlowBlob(res, res?.filename || name);
      } catch (err) {
        console.log("ajax getMiniAppQrCode err", err);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
