/** 状态枚举 */
export const STATE_ENUM = {
  unDisabled: {
    value: 2,
    label: "启用",
  },
  disabled: {
    value: 1,
    label: "禁用",
  },
};
